.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.collapseIn {
  animation: show 300ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;

  opacity: 0;
  transform: rotateX(-90deg);
  transform-origin: top center;

  @exit-style {
      opacity: 0;
    }
}

@keyframes show {
  100% {
    opacity: 1;
    transform: none;
  }
}

.collapseOut {
  animation: hide 600ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;

  opacity: 1;
  transform: rotateX(90deg);
  transform-origin: bottom center;
}

@keyframes hide {
  100% {
    opacity: 0;
    display: none;
  }
}


